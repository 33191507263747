import Head from 'next/head';

interface SEOProps {
  title: string;
  description: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  canonical?: string;
}

const SEO = ({
  title,
  description,
  ogTitle,
  ogDescription,
  ogImage,
  canonical,
}: SEOProps) => {
  const defaultImage = '/assets/logo/logo-big.png';
  const metaDescription = description;
  const metaImageUrl =
    ogImage || `${process.env.NEXT_PUBLIC_BASE_URL}${defaultImage}`;

  return (
    <Head>
      <title>{title}</title>
      <meta name='description' content={metaDescription} />
      <meta name='author' content='Cubet' />
      <meta
        name='google-site-verification'
        content='nOXEgAU0xCQ2ieDvyhFPVrqRGx-IMLgHhHQQQ58jizI'
      />
      <meta property='og:title' content={ogTitle || title} />
      <meta property='og:site_name' content='Cubet' />
      <meta
        property='og:description'
        content={ogDescription || metaDescription}
      />
      <meta property='og:image' content={metaImageUrl} />
      <meta property='og:url' content={canonical || process.env.NEXT_PUBLIC_BASE_URL} />
      <meta property='og:type' content='website' />
      <meta name='twitter:title' content={ogTitle || title} />
      <meta name='twitter:site' content='@CubetTech' />
      <meta
        name='twitter:description'
        content={ogDescription || metaDescription}
      />
      <meta name='twitter:image' content={metaImageUrl} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='p:domain_verify' content='10e10fcb4163b79d506a04e8e524b6e1' />
      <link rel='icon' type='image/x-icon' href='/assets/icons/favicon.ico' />
      <link rel='canonical' href={canonical} />
    </Head>
  );
};

export default SEO;
